import React, { useState, useEffect } from "react";
import "./Loader.css"; // Import your CSS file for styling

const Loader = ({ isLoading }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const images = []; // List of PNG images

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 100); // Change image every 300ms (adjust as needed)
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isLoading, images.length]);

  return (
    <div className="loader-container">
      {isLoading && (
        <div class="loader-1"></div>
        // <img
        //   src={images[imageIndex]}
        //   alt="Loading..."
        //   className="loader-image"
        // />
      )}
    </div>
  );
};

export default Loader;
