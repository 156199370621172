import axios from "axios";

export const COLORS = {
  APPBLUE: "#2F96C5",
  APPORANGE: "#D1985F",
  // Add more colors as needed
};
const productionBaseUrl = "https://adminpanelconnect.kooku.online/userApi";
const developmentBaseUrl = "https://adminpanelconnect.kooku.online/userApi"; //"https://testadmin.ubeetu.com/userApi";

const instance = axios.create({
  baseURL: developmentBaseUrl,
});

export default instance;

window.getUserIP = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching user IP:", error);
    return "Unknown";
  }
};
