import React, { useEffect, useState } from "react";

const DeleteUserPage = (props) => {
  const { isMobile } = props;

  return (
    <div
      className="container-fluid privacyPolicyImgbox"
      style={{
        // width: "100%",
        minHeight: "50rem",
        position: "relative",

        backgroundBlendMode: "overlay",
      }}
    >
      <section style={{ marginTop: "5rem" }}>
        <div className="container-fluid">
          <div className="container refundBellowTextBox">
            <div className="row firstRow">
              <div className="col-md-12">
                <h2 style={{ color: "#2F96C5" }}>Delete User Policy</h2>
                <p className="refundNoteText" style={{ color: "#2F96C5" }}>
                  What happens if I permanently delete my User account?
                </p>
                <p className="text-black">
                  You won't be able to reactivate your account
                </p>
                <p className="text-black">
                  Your profile, photos and everything else you've added will be
                  permanently deleted. You won't be able to retrieve anything
                  you've added.
                </p>
              </div>
            </div>

            <div className="row SecondRow">
              <div className="col-md-12">
                <h2 className="refundHeading2" style={{ color: "#2F96C5" }}>
                  How to Delete My User Account?
                </h2>
                <p className="text-black">
                  You can send an Email to deletemyaccount@olaple.com with your
                  UserID or UID Details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DeleteUserPage;
